<template>
    <div class="layout-topbar">
        <div class="layout-topbar-wrapper">
            <div class="layout-topbar-left">
                <a tabindex="0" class="menu-button" @click="onMenuButtonClick">
                    <i class="pi pi-bars"></i>
                </a>
                <router-link style="height: 60%;" id="logo-link" class="layout-topbar-logo" to="/">
                    <img style="height: 100%;" src="./assets/logo-brasao.png" alt="logo">
                </router-link>
            </div>

            <AppMenu :layoutMode="layoutMode" :sidebarActive="sidebarActive" :sidebarStatic="sidebarStatic" :menuActive="menuActive" :mobileMenuActive="mobileMenuActive" 
                @sidebar-mouse-leave="onSidebarMouseLeave" @sidebar-mouse-over="onSidebarMouseOver" @toggle-menu="onToggleMenu" 
                @menu-click="onMenuClick" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick" />

            <div class="layout-topbar-right">
                <ul class="layout-topbar-actions">
                    <li ref="profile" class="topbar-item user-profile" :class="{'active-topmenuitem fadeInDown': topbarUserMenuActive}">
                        <a @click="onTopbarUserMenuClick">
                            <i class="pi pi-user" style="font-size: 1.6rem"/>
                        </a>
                        <ul class="fadeInDown">
                            <li>
                                <a @click="toggleAutoLoggout()"> 
                                    <input type="checkbox" v-model="autoLoggout"> Deslogar por Inatividade
                                </a>
                            </li>
                            <li>
                                <a @click="toggleNotifications()"> 
                                    <input type="checkbox" v-model="showNotifications">  Habilitar notificações
                                </a>
                            </li>
                            <button class="btn-logout" @click="onLogoutClick()">
                                Sair
                            </button>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import AppMenu from './AppMenu';
export default {
    name: "AppTopbar",
    emits: ["menu-click", "menuitem-click", "root-menuitem-click", "menu-button-click", "toggle-menu",
        "sidebar-mouse-over", "sidebar-mouse-leave", "topbar-usermenu-click", "logout-click"],
    props: {
        topbarItemClick: Boolean,
        topbarUserMenuActive: Boolean,
        topbarUserMenuClick: Boolean,
        activeTopbarItem: String,
        sidebarActive: Boolean,
        sidebarStatic: Boolean,
        layoutMode: String,
        topbarTheme: String,
        menuActive: Boolean,
        mobileMenuActive: Boolean
    },

    data: () => ({
        autoLoggout: true,
        showNotifications: false,
    }),

    mounted() {
        // this.autoLoggout = JSON.parse(localStorage.autoLoggout)
        this.showNotifications = this.$store.getters.getShowNotifications;
    },

    unmounted() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    },
    
    methods: {
        toggleAutoLoggout(){
            this.autoLoggout = !this.autoLoggout;
            localStorage.autoLoggout = this.autoLoggout
        },

        toggleNotifications(){
            this.showNotifications = !this.showNotifications;

            this.$store.dispatch("setShowNotifications", {
                showNotifications: this.showNotifications
            });    
        },

        onLogoutClick(event) {
            this.$emit("logout-click", event);
        },

        onMenuClick(event) {
            this.$emit("menu-click", event);
        },
        
        onMenuItemClick(event) {
            this.$emit("menuitem-click", event);
        },

		onRootMenuItemClick(event) {
			this.$emit("root-menuitem-click", event);
		},

        onMenuButtonClick(event) {
            this.$emit("menu-button-click", event);
        },

        onToggleMenu(event) {
            this.$emit("toggle-menu", event);
        },

        onTopbarUserMenuClick(event) {
            this.$emit("topbar-usermenu-click", event)
        },

        onSidebarMouseOver() {
            this.$emit("sidebar-mouse-over");
        },

        onSidebarMouseLeave() {
            this.$emit("sidebar-mouse-leave");
        }, 
    }, 
    components: { 
        AppMenu
    }
};
</script>

<style scoped>
    .btn-logout {
        width: 100%;
        text-align: inherit;
        padding: 10px 20px;
        background: transparent;
        border: none;
        color: #545b67;
        font-weight: 500;
        cursor: pointer;
    }
    .btn-logout:hover {
        background: #eef1f3;
    }
</style>