import axios from 'axios';
import store from "../store/store";
import { getUnixTime } from "date-fns";
import EventBus from '../event-bus';

const api = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL
});

// Executa sempre antes de todo request feito pelo axios
api.interceptors.request.use(function (config) {
	if(store.getters.getExpire && (getUnixTime(new Date) > store.getters.getExpire)) {
		store.dispatch('setLoggout', {
			user: {},
			token: "",
			isLogged: false,
			expire: null
		});
	}
    
    // Atualiza tempo da ultima ação do usuario
	// store.dispatch('refreshUserActivityTime')
    return config
});

api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response && error.response.data && error.response.data.code) {
        if(error.response.data.code === 'PASSWORD_CHANGE_REQUIRED') {
            EventBus.emit('show-global-dialog', 'PASSWORD_CHANGE_REQUIRED');
        }
        if(error.response.data.code === 'RELOG_REQUIRED') {
            EventBus.emit('show-global-dialog', 'RELOG_REQUIRED');
        }
    }
    return Promise.reject(error);
});


export default api;
