<template>
	<div class="login-body">
		<div class="login-wrapper">
			<form class="login-panel" @submit.prevent="onSubmitLogin()">
                <InputText v-model="username" id="username" placeholder="Usuário" />
                <Password v-model="password" id="password" placeholder="Senha" :feedback="false" />
                <Button :loading="loading" label="ENTRAR" type="submit"></Button>
            </form>
        </div>
    </div>
</template>

<script>
import { getUnixTime } from "date-fns";

export default {
    name : "Login",

    data () {
        return {
            username: null,
            password: null,
            loading: false,

            site_id: null
        }
    },

    created() {
		this.checkSiteId()
	},

    methods: {
        checkSiteId() {
            const hostname = window.location.hostname

            switch (hostname) {
                case 'afiliados.sortebet.io':
                    this.site_id = 6
                    break;

                case 'afiliados.seupalpite.bet':
                    this.site_id = 2
                    break;

                default:
                    break;
            }
        },

        onSubmitLogin() {
            this.loading = true;

            this.$api
                .post("/auth/login", {
                    username: this.username,
                    password: this.password,
                    site_id: this.site_id
                })
                .then(response => {

                    // GETTING LOGIN INFO
                    this.access_token = response.data.access_token;
                    let expire_date = getUnixTime(new Date);
                    expire_date += response.data.expires_in - 300;

                    this.$api
                        .get("/auth/me", {
                            headers: {
                                Authorization: `Bearer ${this.access_token}`
                            }
                        })
                        .then(response => {
                            this.user_logged = response.data;
                            // SELLERS CAN'T ACESS THE SYSTEM 
                            if ((this.user_logged.type == "cambista" && !this.user_logged.is_affiliate)|| this.user_logged.type == "operador") {
                                this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Usuário não permitido', life: 3000 });
                                return;
                            }
            
                            // KEEPING IN VUEX 
                            this.$store.dispatch("setLogged", {
                                isLogged: true,
                                user: this.user_logged,
                                token: this.access_token,
                                expire: expire_date
                            });

                            var menuItems = this.getMenuItems()

                            if(this.loggedUser().type == 'master') {
                                for (let i = 0; i < menuItems.length; i++) {
                                    menuItems[i].selected = true; 
                                    if (menuItems[i].items) {
                                        for (let j = 0; j < menuItems[i].items.length; j++) {
                                            menuItems[i].items[j].selected = true; 
                                        }
                                    }
                                }
                            }  else if(this.loggedUser().type == 'cambista' && this.loggedUser().is_affiliate) {
                                const index = menuItems.findIndex(item => item.name === '/affiliates');
                                
                                menuItems[index].selected = true
                                menuItems[index].items[1].selected = true; 
                                menuItems[index].items[2].selected = true; 
                            } else {
                                menuItems =  this.updateMenuItems(this.getMenuItems(), response.data.menuConfig)
                            }

                            this.$store.dispatch("setMenuItems", {
                                menuItems: menuItems
                            });

                            this.$router.push({
                                path: '/'
                            });

                        })
                        .catch(() => {
                            this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Não foi possível carregar os dados.', life: 3000 });

                            this.loading = false;
                        });

                    return;
                })

                .catch(() => {
                    this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Verifique os dados novamente', life: 3000 });

                    this.loading = false;
                });
        
        },
    }
};
</script>
