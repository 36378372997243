<template>
    <div class="menu-wrapper" :class="{ 'layout-sidebar-active' : sidebarActive}"
        @click="onMenuClick" @mouseover="onSidebarMouseOver" @mouseleave="onSidebarMouseLeave">
        <div class="sidebar-logo">
            <router-link to="/">
                <img src="./assets/logo-brasao.png" alt="logo">
            </router-link>
            <a class="toggle-btn" v-if="sidebarActive" @click="onToggleMenu($event)">
                <span style="font-size: 1.3rem;" class="pi pi-lock"></span>
            </a>
        </div>
        <div class="layout-menu-container">
            <AppSubmenu class="layout-menu" :items="menu.filter(item => item.selected)" :layoutMode="layoutMode" :parentMenuItemActive="true" :menuActive="menuActive" :mobileMenuActive="mobileMenuActive" :root="true" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick" />
        </div>
    </div>
</template>

<script>
import AppSubmenu from "./AppSubmenu";
export default {
    name: "AppMenu",
    emits: ["menu-click", "menuitem-click", "root-menuitem-click", "toggle-menu", "sidebar-mouse-over", "sidebar-mouse-leave"],
    props: {
        sidebarActive: Boolean,
        sidebarStatic: Boolean,
        layoutMode: String,
        menuActive: Boolean,
        mobileMenuActive: Boolean
    },
    mounted() {
        this.menu = this.$store.getters.getMenuItems;
    },

    data() {
        return {
            menu: []
        }
    },
    methods: {
        onSidebarMouseOver() {
            if (this.layoutMode === "sidebar" && !this.sidebarStatic) {
                this.$emit("sidebar-mouse-over");
            }
        },
        onSidebarMouseLeave() {
            if (this.layoutMode === "sidebar" && !this.sidebarStatic) {
                setTimeout(() => {
                    this.$emit("sidebar-mouse-leave");
                }, 250);
            }
        },
        onToggleMenu(event) {
            this.$emit("toggle-menu", event);
        },
        onMenuClick(event) {
            this.$emit("menu-click", event);
        },
        onMenuItemClick(event) {
            this.$emit("menuitem-click", event);
        },
		onRootMenuItemClick(event) {
			this.$emit("root-menuitem-click", event);
		}
    },
    components: { AppSubmenu },
};
</script>

<style scoped>
    .toggle-btn {
        background: var(--primary-lighter-color);
        padding: 5px;
        border-radius: 7px;
    }

    .toggle-btn:hover {
        background: var(--primary-color);
        color: white;
    }

   
</style>