import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';

// Create a new store instance.
const store = createStore({
	state () {
		return {
			token: "",
			isLogged: false,
			user: {},
			expire: null,
			menuItems: [],
			colorScheme: 'light',
			showNotifications: false
		}
	},

	getters: {
		getToken: state => {
			return state.token;
		},

		getColorScheme: state => {
			return state.colorScheme;
		},

		// GETTING USER 
		getUser: state => {
			return state.user;
		},

		// GETTING STATE OF USER
		getLogged: state => {
			return state.isLogged;
		},

		getMenuItems: state => {
			return state.menuItems;
		},

		getShowNotifications: state => {
			return state.showNotifications;
		},

	},

	mutations: {
		// SETTING LOGIN
		SET_LOGGED(state, payload) {
			state.isLogged = payload.isLogged;
			state.user = payload.user;
			state.token = payload.token;
			state.expire = payload.expire;
		},
		
		// SETTING LOGOUT
		SET_LOGGOUT(state, payload) {			
			state.token = payload.token;
			state.user = payload.user;
			state.isLogged = payload.isLogged;
			state.expire = payload.expire;
		},

		SET_COLORSCHEME(state, payload) {			
			state.colorScheme = payload.colorScheme;
		},

		SET_MENUITEMS(state, payload) {			
			state.menuItems = payload.menuItems;
		},

		SET_SHOW_NOTIFICATIONS(state, payload) {
			state.showNotifications = payload.showNotifications;
		}

	},

	actions: {
		setLogged(context, payload) {
			context.commit('SET_LOGGED', payload);
		},

		// SETTING LOGOUT
		setLoggout(context, payload) {
			context.commit('SET_LOGGOUT', payload);
		},

		setMenuItems(context, payload) {
			context.commit('SET_MENUITEMS', payload);
		},

		setColorScheme(context, payload) {
			context.commit('SET_COLORSCHEME', payload);
		},

		setShowNotifications(context, payload) {
			context.commit('SET_SHOW_NOTIFICATIONS', payload);
		},
	},

	plugins: [createPersistedState()],
})

export default store